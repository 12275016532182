.body-about {

    background-image: url('../../assets/bg2.png') ;
    background-size: cover;
    background-repeat: no-repeat;
    height: fit-content;
    width: 100%;
    color: white;
    padding: 200px 0 200px 0;
    
}
.body-about p {
    color: #919191 !important;
    font-size: 20px;
}

.body-about .img1 {
    position: absolute;
    z-index: 1;
    width: 400px;
    height: 500px;
    top: 0;
    left: 0;
    transition: .5s ease;
    object-fit: cover;
}

.body-about .img2 {
    position: absolute;
    z-index: 2;
    width: 400px;
    height: 500px;
    top: 100px;
    left: 100px;
    object-fit: cover;
    transition: 1s ease;
}

.body-about .img1:hover,
.body-about .img2:hover {
    box-shadow: 0 0 0 10px #ffffff;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    transition: 1s ease;
}

@media (max-width:1024px) { 
    .body-about {
        padding:  100px 20px;
    }
}


@media (max-width: 768px) { 

    .body-about {
      
        padding: 250px 0 250px 0;
    }
    .body-about .img2,.body-about .img1 {
        width: 300px;
    height: 300px;

    
    }
    .body-about h1 {
        margin-top: -14rem;
    }
    .body-about p {
        margin-top: -1rem;
    }

}

@media (max-width: 545px) { 

    .body-about{
        width: 100%;
        height: 700px;
        background-size: cover;
    }

    .body-about h1 {
       position: relative;
       top: -2rem;
    }

    .body-about p {
        font-size: 14px;
        letter-spacing: .25px;
    }
    .body-about .img1,
    .body-about .img2 {
        margin-top: -150px !important;
        width: 160px;
        height: 160px;
        object-fit: cover;
    }
    .body-about .img2 {
        left: 0;
        top: 150px !important;
    }
} 


@media (max-width: 375px) { 

    .body-about{
        width: 100%;
        height: 500px;
        background-size: cover;
    }

    .body-about h1 {
       position: relative;
       top: -2rem;
    }

    .body-about p {
        font-size: 10px;
    }
    .body-about .img1,
    .body-about .img2 {
        margin-top: -150px !important;
        width: 150px;
        height: 150px;
        object-fit: cover;
    }
    .body-about .img2 {
        left: 0;
        top: 150px !important;
    }
} 

@media (max-width: 320px) { 

    .body-about{
        width: 100%;
        height: 500px;
        background-size: cover;
    }

    .body-about h1 {
       position: relative;
       top: -2rem;
    }

    .body-about p {
        font-size: 9.5px;
    }
    .body-about .img1,
    .body-about .img2 {
        margin-top: -150px !important;
        width: 120px;
        height: 120px;
        object-fit: cover;
    }
    .body-about .img2 {
        left: 0;
        top: 150px;
    }
} 