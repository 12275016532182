.body-contact {
     background-image: url('../../assets/whiteBG.jpg');

     /* padding: 400px 0 400px 0;  */

   
}

.body-contact img {
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}