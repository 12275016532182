.body {
    padding-top: 12rem;
    padding-bottom: 10rem;
}

.body h3 {
    margin-top: 2rem;
}

@media (max-width: 768px) { 

    .body h3 {
        margin-left: 1.75rem;
    }

 }
