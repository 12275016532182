.body-footer {
    /* background-image: url('../../assets/contact.jpeg');
    background-repeat: no-repeat;
    background-size: contain; */
    background-color: #3f6e31;
    padding: 4rem 4rem;
    color: white;
}

.vl {
    border-left: 6px solid white;
    height: 500px;
}


.item1,.item2 {
    border-right: 1px solid white;
}

.social-icon {
    margin: .25rem;
}

@media (max-width: 768px) {
    .body-footer {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
 }


@media (max-width: 425px) { 

    .body-footer {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

   .item1,.item2 ,.item3 {
    border-right: none;
    margin-bottom: 1.5rem;
   }


}

@media screen  and (max-width: 350px) {
    .social-icon {
        margin: 0;
    }
}