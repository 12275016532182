
.carousel-item img {
    backdrop-filter: blur(8px) brightness(80%);
}

.carousel-caption {
    top: 40% !important;
    background-color: #0000002b;
}

.carousel-caption p {
    font-size: 28px !important;
}

@media (max-width: 768px) { 

    .carousel-caption h3 {
        font-size: 40px !important;
    }
    .carousel-caption p {
        font-size: 30px !important;
    }
}



@media (max-width: 425px) { 
    .carousel-caption {
      top: 30% !important;
      font-weight: 400 !important;
     
    }
    .carousel-caption h3 {
        font-size: 30px !important;
    }
    .carousel-caption p {
        font-size: 22px !important;
    }
    }
    