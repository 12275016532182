
:root {
  --light: #f8f9fa;
  --secondary: #adb5bd;
  --dark: #343a40;
  --primary-color: #3f6e31;
  --secondary-color: #2ec4b6;
  --boder: #e9ecef;

  overflow-x: hidden;

}

::selection {
  background-color: var(--primary-color);
  color: var(--light);
}

html {
  scroll-behavior: smooth;
}

body {
overflow-x: hidden;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

* > * {
  box-sizing: border-box;
}

/* global styling */

.text-light {
  color: var(--light);
}
.text-secondary {
  color: var(--secondary);
}

.text-primary {
  color: var(--primary-color);
}

.bg-light {
  background-color: var(--light);
}

.container-inside {
  max-width: 1200px;
  padding: 0 15px;
  margin: auto;
}

.img-fluid {
  width: 100%;
}

.text-title {
  font-weight: bold;
 
}
.display-1 {
  font-size: 22px;
}

.display-2 {
  font-size: 16px;
}

.display-3 {
  font-size: 14px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.btn {
  padding: 15px 20px;
  border: none;
  cursor: pointer;
}

.btn-primary {
  border-radius: 4px;
  background-color: var(--secondary-color);
}

.object-fit {
  max-height: 120px;
  height: 80px;
  width: 80px;
  object-fit: fill;
}

.d-flex {
  display: flex;
}


.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mb-3 {
  margin-bottom: 30px;
}



.m-0 {
  margin: 0;
}

.px-1 {
  padding-left: 5px;
  padding-right: 5px;
}

.px-2 {
  padding-left: 20px;
  padding-right: 20px;
}

.py-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-3 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.shadow {
  box-shadow: 0 4px 20px rgba(149, 157, 165, 0.2);
}

.shadow-twilight {
  box-shadow: rgb(220 220 220) 0px 2px 10px;
}

.light-hover {
  transition: .5s all;
}
.light-hover:hover {
  box-shadow: rgb(220 220 220) 0px 2px 10px;
}


/* global styling */

.video {
  position: relative !important;
  z-index: 999 !important;
  object-fit: contain;
  /* width: 80vw !important; */
  height: 80vh !important;

  top: 0 !important;
  left: 0 !important;
}

.carousel-item img {
  height: 80vh !important;
  object-fit: cover !important;
  filter: brightness(.75);
}

.carousel-caption{
  top: 50%;
  font-size:  46px;
}

.carousel-caption h3{

  font-size:  46px;
}

.carousel-caption p{
  font-size:  36px;
}

@media (max-width: 425px) { 

  

.carousel-caption {
  top: 30% !important;
  font-weight: 800;
}
}
