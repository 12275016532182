.body-product {
    background-image: url('../../assets/yellowBG.jpeg');
    
    padding: 4rem 4rem;
}

.body-product img {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}


@media (max-width: 768px) { 
    .body-product {
        padding: 2rem 2rem;
    }
}
@media (max-width: 425px) { 

.body-product {
    padding: 0;
}
}